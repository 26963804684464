// extracted by mini-css-extract-plugin
export var contact = "footer-module--contact--2ada8";
export var dBlock = "footer-module--d-block--7cddf";
export var dFlex = "footer-module--d-flex--43309";
export var dInline = "footer-module--d-inline--fdaba";
export var dLgBlock = "footer-module--d-lg-block--271dc";
export var dLgFlex = "footer-module--d-lg-flex--333ba";
export var dLgInline = "footer-module--d-lg-inline--6bb86";
export var dLgNone = "footer-module--d-lg-none--c3edb";
export var dMdBlock = "footer-module--d-md-block--18441";
export var dMdFlex = "footer-module--d-md-flex--56b22";
export var dMdNone = "footer-module--d-md-none--f0d4f";
export var dNone = "footer-module--d-none--83a31";
export var footer = "footer-module--footer--954ee";
export var nav = "footer-module--nav--d4c8a";
export var socialMedia = "footer-module--socialMedia--6675a";