import { Link } from "gatsby";
import React from "react";
import { GetContact } from "../../queries/get-contact";
import { GetSocialMedia } from "../../queries/get-socialmedia";
import Image from "../../components/image/image";
import { prependLocale } from "../../i18n/prepend-locale";
import { currentLocale } from "../../../src/i18n/locale-provider";
import { footer, contact, socialMedia, nav } from "./footer.module.scss";

const Footer = () => {
  const data = GetContact();
  const socialMediaLinks = GetSocialMedia();

  return (
    <footer id="contact/" className={footer}>
      <h3 className="section-title">Contact</h3>
      <div className={contact}>
        <p>{data.Contact.Address}</p>
        <a href={`mailto: ${data.Contact.Email}`}>{data.Contact.Email}</a>
        <div className={socialMedia}>
          {socialMediaLinks.map((entry) => (
            <a
              aria-label={entry.id}
              key={entry.id}
              href={entry.Url}
              target="_blank"
              rel="noreferrer"
            >
              <Image gatsbyImage={entry.Icon} alt={entry.Url} />
            </a>
          ))}
        </div>
      </div>
      <div className={nav}>
        <Link to={prependLocale("/imprint/")}>
          {currentLocale === "de" ? "Impressum" : "Imprint"}
        </Link>
        <Link to={prependLocale("/privacy/")}>
          {currentLocale === "de" ? "Datenschutz" : "Privacy"}
        </Link>
      </div>
      {/* <div className={contact}>
      <h3 className="section-title">CONTACT</h3>
        <div className={footerContact}>
            <p>{data.Contact.Address}</p>
            <br></br>
            <p>
              <a href={`mailto: ${data.Contact.Email}`}>{data.Contact.Email}</a>
            </p>
        </div>
      </div>
      <div className={socialMedia}>
        {socialMediaLinks.map((entry) => (
          <a
            aria-label={entry.id}
            key={entry.id}
            href={entry.Url}
            target="_blank"
            rel="noreferrer"
          >
            <Image gatsbyImage={entry.Icon} alt={entry.Url} />
          </a>
        ))}
      </div>
      <div className={footerNav}>
        <Link to={prependLocale("/imprint/")}>
        {currentLocale === "de" ? "Impressum" : "Imprint"}
        </Link>
        <Link to={prependLocale("/privacy/")}>
        {currentLocale === "de" ? "Datenschutz" : "Privacy"}
        </Link>
      </div> */}
    </footer>
  );
};

export default Footer;
