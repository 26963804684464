import { graphql, useStaticQuery } from "gatsby";
import useLocalizedData from "../i18n/use-localized-data";


export const GetContact = () => {
  const data = useStaticQuery(graphql`
    query getContact {
      allStrapiWebsite {
        nodes {
          locale
          Contact {
            Address
            Email
          }
        }
      }
    }
  `);
  return useLocalizedData(data.allStrapiWebsite);
};
