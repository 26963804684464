import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import Image from "../../components/image/image";
import Navigation from "../navigation/navigation";
import { prependLocale } from "../../i18n/prepend-locale";
import { GetHeader } from "../../queries/get-header";
import { headerlogo, menuToggle, navOpen, sticky } from "./header.module.scss";

const Header = ({ navOpenChange }) => {
  const data = GetHeader();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const close = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.documentElement.style.scrollBehavior = "auto";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.documentElement.style.scrollBehavior = "";
    setIsOpen(false);
    if (navOpenChange) {
      navOpenChange(false);
    }
  };

  const open = () => {
    const scrollY = window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    setIsOpen(true);
    if (navOpenChange) {
      navOpenChange(true);
    }
  };

  const toggle = () => {
    isOpen ? close() : open();
  };

  useEffect(() => {
    const headerHeight = getComputedStyle(document.documentElement)
      .getPropertyValue("--header-height")
      ?.replace("px", "");
    setIsScrolled(window.scrollY > headerHeight / 2);
    window.addEventListener("scroll", () => {
      if (document.body.style.position !== "fixed") {
        setIsScrolled(window.scrollY > headerHeight / 2);
      }
    });
  }, []);

  return (
    <header className={`${isScrolled ? sticky : ""}`}>
      <Link onClick={close} to={prependLocale("/")} className={headerlogo}>
        <Image
          gatsbyImage={data.Home.Logo}
          className={`${isOpen ? navOpen : ""}`}
          alt="thirdlabs Logo"
        />
      </Link>
      <Navigation isOpen={isOpen} close={close} />
      <button
        onClick={() => toggle()}
        className={`${menuToggle} ${isOpen ? navOpen : ""}`}
        aria-label="Menu Button"
      >
        <span></span>
      </button>
    </header>
  );
};

export default Header;
