import { graphql, useStaticQuery } from "gatsby";


export const GetSocialMedia = () => {
  const data = useStaticQuery(graphql`
    query getSocialMedia {
      strapiWebsite {
        SocialMedia {
          id
          Url
          Icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  return data.strapiWebsite.SocialMedia;
};
